:root {
  text-align: center;
  background-color: #282c34;
}

.header {
  height: 50px;
  width: 100%;
  background-color: rgb(4, 23, 36);
  background-image: url("../public/header.png");
  background-repeat: no-repeat;
  background-size: auto 30px;
  background-position: center;
  background-clip: content-box;
}

.authentication-box {
  background-color: rgb(5, 27, 68);
  width: 100%;
  max-width: 480px;
  height: 100vh;
  background: url("../public/bg_app.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.text-pending {
  height: 200px;
  margin: 1em;
  margin-top: 2em;
}

.text {
  height: 150px;
  margin-top: 50px;
}

.challenges {
  width: 100%;
  padding: 1em;
  padding-top: 0em;
}

.progress {
  height: 100px;
  padding: 0em;
}

.session-info {
  padding: 2em;
}

.promotion {
  margin-top: 0px;
  height: 120px;
}

.buttons {
  right: 1em;
}

.assisted-login-buttons-container {
  padding-top: 1em;
  width: 100%;
}

.assisted-login-buttons {
  margin: 0.5em;
  background-color:rgba(3, 49, 81, 0.1);
}
